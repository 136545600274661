// number lib
import integer from '../../number/lib/integer.lib.number';

// risk constants
import GRADES from '../constants/grades.constant.risk';

const convertScoreToGrade = (score) => {
  const roundedScore = integer(score);
  return (
    Object.entries({...GRADES})
      .sort(([keyA, scoreA], [keyB, scoreB]) => scoreB - scoreA)
      .find(([grade, gradeScore]) => roundedScore >= gradeScore)?.[0] || 'F'
  );
};

export default convertScoreToGrade;
