// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import resetMeltwaterNewsAction from './actions/resetNews.action.redux.meltwater';
import setGeneralMeltwaterDataAction from './actions/setGeneralData.action.redux.meltwater';

// style redux constants
import DEFAULT_MELTWATER_REDUX_STATE from './constants/defaultState.constant.redux.meltwater';

const meltwaterSlice = createSlice({
  name: 'meltwater',
  initialState: DEFAULT_MELTWATER_REDUX_STATE,
  reducers: {
    resetMeltwaterNews: resetMeltwaterNewsAction,
    setGeneralMeltwaterData: setGeneralMeltwaterDataAction,
  },
});

export const {resetMeltwaterNews, setGeneralMeltwaterData} =
  meltwaterSlice.actions;

export default meltwaterSlice.reducer;
