// ant components
import {Dropdown, Space} from 'antd';

// ant icons
import {DownOutlined} from '@ant-design/icons';

// dom lib
import DOMPurify from 'dompurify';

// local components
// import Avatar from './components/Avatar';
import ChatContainer from './components/ChatContainer';
// import Close from './components/Close';
import Container from './components/Container';
// import HaloGptButton from './components/HaloGptButton';
import Footer from './components/Footer';
import Header from './components/Header';
import Icon from './components/Icon';
// import Input from './components/Input';
import InputContainer from './components/InputContainer';
import Message from './components/Message';
import MessageBox from './components/MessageBox';
import MessageInput from './components/MessageInput';
import MessageRow from './components/MessageRow';
import Messages from './components/Messages';
import Option from './components/Option';
import OptionsContainer from './components/OptionsContainer';
import Send from './components/Send';

// local handlers
import onMessageSelectHandler from './handlers/onMessageSelect.handler';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// textarea
import TextareaAutosize from 'react-textarea-autosize';

const PageHaloGpt = ({
  loading,
  message,
  messages,
  onHideModal,
  onMessage,
  onPostMessage,
  onQuerySelect,
  onShowModal,
  options,
  scrollerDom,
  showFreeFormInput,
  visible,
}) => (
  <Container>
    {/* <HaloGptButton onClick={visible ? onHideModal : onShowModal}>
      <i className="mdi mdi-robot-happy-outline" /> HaloGPT
    </HaloGptButton> */}
    <ChatContainer>
      <Header>
        <Icon />
        HaloGPT
        {/* <Close onClick={onHideModal}>
          <i className="mdi mdi-close" />
        </Close> */}
      </Header>
      <Messages ref={scrollerDom}>
        {messages.map((messageGroup) =>
          messageGroup.user ? (
            <MessageRow key={messageGroup.id} rtl>
              <MessageBox rtl>
                {messageGroup.messages.map((message) => (
                  <Message
                    key={`${messageGroup.id}${message.id}`}
                    onClick={
                      !messageGroup.action ? null : () => onQuerySelect(message)
                    }
                    user
                  >
                    <div dangerouslySetInnerHTML={{__html: message.message}} />
                  </Message>
                ))}
              </MessageBox>
              {/* <Avatar>U</Avatar> */}
            </MessageRow>
          ) : (
            <MessageRow key={messageGroup.id}>
              {/* <Avatar>HG</Avatar> */}
              <MessageBox>
                {messageGroup.messages.map((message) => (
                  <Message
                    key={`${messageGroup.id}${message.id}`}
                    onClick={
                      !messageGroup.action ? null : () => onQuerySelect(message)
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          message.message.replace(/\n/g, '<br />')
                        ),
                      }}
                    />
                  </Message>
                ))}
              </MessageBox>
            </MessageRow>
          )
        )}
        {loading && (
          <MessageRow>
            {/* <Avatar>HG</Avatar> */}
            <MessageBox>
              <Message>Typing ...</Message>
            </MessageBox>
          </MessageRow>
        )}
      </Messages>
      <Footer>
        <MessageInput disabled={loading}>
          {/* {options.map((option) => (
            <Option key={option.id} onClick={() => onQuerySelect(option)}>
              {option.message}
            </Option>
          ))} */}
          <OptionsContainer>
            <Dropdown
              menu={{
                items: [...options].map((option) => ({
                  label: option.message,
                  key: option.id,
                  option,
                })),
                onClick: onMessageSelectHandler({options, onQuerySelect}),
              }}
            >
              <Option fullWidth>
                <Space>
                  Select a topic
                  <DownOutlined />
                </Space>
              </Option>
            </Dropdown>
          </OptionsContainer>
          {showFreeFormInput && (
            <InputContainer onSubmit={onPostMessage}>
              {/* <Input
              value={message}
              placeholder="Type your message here"
              onChange={(e) => onMessage(e.target.value)}
            /> */}
              <TextareaAutosize
                minRows={1}
                maxRows={15}
                value={message}
                onChange={(e) => onMessage(e.target.value)}
                placeholder="Type your message here"
                style={{
                  background: 'transparent',
                  border: 'none',
                  outline: 'none',
                  padding: '4px 0px 4px 8px',
                  margin: '0px',
                  display: 'block',
                  fontSize: '12px',
                  flex: '1',
                  width: '100%',
                  resize: 'none',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    onPostMessage();
                    return false;
                  }
                }}
              />
              {!!message.trim().length && (
                <Send onClick={onPostMessage}>
                  <i className="mdi mdi-arrow-up" />
                </Send>
              )}
            </InputContainer>
          )}
        </MessageInput>
        {/* <Avatar>U</Avatar> */}
      </Footer>
    </ChatContainer>
  </Container>
);

PageHaloGpt.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  messages: PropTypes.array,
  onHideModal: PropTypes.func,
  onMessage: PropTypes.func,
  onPostMessage: PropTypes.func,
  onQuerySelect: PropTypes.func,
  onShowModal: PropTypes.func,
  options: PropTypes.array,
  scrollerDom: PropTypes.object,
  showFreeFormInput: PropTypes.bool,
  visible: PropTypes.bool,
};

export default PageHaloGpt;
