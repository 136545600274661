// cbInsights api
import loadCbInsightsBusinessRelationshipsApi from '../../cbInsights/api/loadBusinessRelationships.api.cbInsights';
import loadCbInsightsDealsApi from '../../cbInsights/api/loadDeals.api.cbInsights';
import loadCbInsightsOrganizationApi from '../../cbInsights/api/loadOrganization.api.cbInsights';
import loadCbInsightsPeopleApi from '../../cbInsights/api/loadPeople.api.cbInsights';

// creditSafe api
import loadCorporateHierarchyApi from '../../creditSafe/api/loadCorporateHierarchy.api.creditSafe';
import loadCreditSafeFinancialsApi from '../../creditSafe/api/loadFinancials.api.creditSafe';

// data services
import loadAndAttachAsyncJsonToObjectService from '../../data/services/loadAndAttachAsyncJsonToObject.service.data';

// supplier api
import getSupplierBlackKiteComplianceFindingsApi from '../../supplier/api/getBlackKiteComplianceFindings.api.supplier';
import getSupplierDashboardApi from '../../supplier/api/getDashboard.api.supplier';

// supplier lib
import getCompanyId from '../../supplier/lib/getCompanyId.lib.supplier';

// tag services
import getCompanyTagsService from '../../tag/services/getCompanyTags.service.tag';

// tenant api
import getHaloScoresApi from '../../tenant/api/getHaloScores.api.tenant';

const getByIdApi = async ({companyId}) =>
  new Promise(async (resolve, reject) => {
    try {
      const [dashboardData, blackKiteComplianceFindings, supplierHaloScores] =
        await Promise.all([
          getSupplierDashboardApi(companyId),
          getSupplierBlackKiteComplianceFindingsApi(companyId),
          getHaloScoresApi(companyId),
        ]);

      const companyWithoutTags = {
        ...dashboardData,
        CompanyId: dashboardData.CompanyId,
        weightedHaloScores: supplierHaloScores,
        complianceFindings: blackKiteComplianceFindings,
      };

      const {data: tags} = await getCompanyTagsService({
        companyId: companyWithoutTags.CompanyId,
      });

      const company = {
        ...companyWithoutTags,
        tags,
      };

      const haloCompanyId = getCompanyId(company);

      if (!company) return reject('Not found');
      const companyWithLoadedData = await loadAndAttachAsyncJsonToObjectService(
        {
          company,
          dataObject: {
            corporateHierarchy: loadCorporateHierarchyApi(haloCompanyId),
            financials: loadCreditSafeFinancialsApi(haloCompanyId),
          },
        }
      );

      const cbInsights = await loadAndAttachAsyncJsonToObjectService({
        company: {},
        dataObject: {
          businessRelationships:
            loadCbInsightsBusinessRelationshipsApi(haloCompanyId),
          deals: loadCbInsightsDealsApi(haloCompanyId),
          organization: loadCbInsightsOrganizationApi(haloCompanyId),
          people: loadCbInsightsPeopleApi(haloCompanyId),
        },
      });
      if (!!Object.keys(cbInsights).length)
        companyWithLoadedData.cbInsights = cbInsights;
      return resolve(companyWithLoadedData);
    } catch (error) {
      reject(error);
    }
  });

export default getByIdApi;
