// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// meltwater containers
import MeltwaterNewsContainer from '../../containers/MeltwaterNewsContainer/MeltwaterNewsContainer';

// local page
import page from './page';

// react
import React from 'react';

const MeltwaterNewsPage = () => (
  <SubPage page={page}>
    <MeltwaterNewsContainer />
  </SubPage>
);

export default MeltwaterNewsPage;
