// charts
import Highcharts from 'highcharts';

// layout colors
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const DailyNewsChart = ({data = []}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'line',
        height: '240px',
      },
      colors: [blueGrey300Color],
      title: {
        text: 'Trend',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
        },
      },
      series: [
        {
          name: 'Number of articles',
          data,
        },
      ],
    });
    // eslint-disable-next-line
  }, [data]);
  return <div id={id} />;
};

DailyNewsChart.propTypes = {
  data: PropTypes.array,
};

export default DailyNewsChart;
