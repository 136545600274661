// ant components
import {Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FinancialStatementsCard = ({
  currency,
  categories,
  entries,
  haloGptMessage,
  haloGptResponse,
  printView,
  title,
}) => {
  const columns = generateColumns({currency, categories});

  return (
    <StatementBlock
      displayHaloGptResponse={printView}
      haloGptMessage={haloGptMessage}
      haloGptResponse={haloGptResponse}
      title={title}
    >
      <Table
        columns={columns}
        dataSource={entries}
        pagination={false}
        rowKey="status"
        size="small"
      />
    </StatementBlock>
  );
};

FinancialStatementsCard.propTypes = {
  categories: PropTypes.array,
  currency: PropTypes.string,
  entries: PropTypes.array,
  haloGptMessage: PropTypes.string,
  haloGptResponse: PropTypes.string,
  printView: PropTypes.bool,
  title: PropTypes.string,
};

export default FinancialStatementsCard;
