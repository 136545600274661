// ant components
// import {Tag} from 'antd';

// company routes
import companyRoute from '../../../../pages/CompanyPage/route';

// local components
import Image from './components/Image';
import ImageContainer from './components/ImageContainer';
import Info from './components/Info';
import Link from './components/Link';
import Tag from './components/Tag';
import TagContainer from './components/TagContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// react redux
import {useSelector} from 'react-redux';

// supplier lib
import getSupplierLogoUrl from '../../../../../supplier/lib/getLogoUrl.lib.supplier';

// tag constants
import TAG_BACKGROUND_COLOR from '../../../../../tag/constants/backgroundColor.constant.tag';

const CompanySupplierColumn = ({company}) => {
  const logoURL = getSupplierLogoUrl({path: company.Domain});
  const {tags} = useSelector((state) => state.tag);

  const companyTags = [...tags].reduce((combined, tag) => {
    const companyTag = [...(tag?.companies || [])].find(
      ({company_id}) => company_id.replace('company_', '') === company.CompanyId
    );
    if (!companyTag) return combined;
    return [...combined, {...companyTag}];
  }, []);

  return (
    <Link to={companyRoute(company.CompanyId)}>
      {!!logoURL && (
        <ImageContainer>
          <Image src={logoURL} />
        </ImageContainer>
      )}
      <Info>
        <div>{company.CompanyName || company.Firmographic.LegalName}</div>
        <div>{company.Ticker || 'Private'}</div>
        {!!companyTags.length && (
          <TagContainer>
            {companyTags.map((tag) => (
              <Tag
                color={
                  !!tag?.json?.background_color
                    ? `#${tag?.json?.background_color}`
                    : TAG_BACKGROUND_COLOR
                }
                key={tag.id}
              >
                {tag.name}
              </Tag>
            ))}
          </TagContainer>
        )}
        {/* <Tag
          color={blueGrey300Color}
          icon={<i className="mdi mdi-arrow-right" />}
        >
          Go to dashboard
        </Tag> */}
      </Info>
    </Link>
  );
};

CompanySupplierColumn.propTypes = {
  company: PropTypes.object,
};

export default CompanySupplierColumn;
