// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// wordCloud components
import ReactWordcloud from 'react-wordcloud';

const WordCloudCard = ({words = {}}) => {
  const listOfWords = Object.entries(words)
    .map(([word, count]) => ({
      text: word,
      value: count,
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 30);

  return (
    <StatementBlock title="Word Cloud">
      <div style={{height: 400, width: '100%'}}>
        <ReactWordcloud
          words={listOfWords}
          options={{
            deterministic: true,
            enableTooltip: false,
            fontFamily: 'Open Sans',
            fontSizes: [20, 60],
            rotationAngles: [0, 90],
            rotations: 1,
            scale: 'sqrt',
            spiral: 'archimedean',
          }}
        />
      </div>
    </StatementBlock>
  );
};

WordCloudCard.propTypes = {
  words: PropTypes.object,
};

export default WordCloudCard;
