// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import HighlightedGrade from '../../../components/HighlightedGrade/HighlightedGrade';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// number lib
import integer from '../../../../number/lib/integer.lib.number';

export default generateColumn({
  title: 'Financial Metrics Rating',
  width: 180,
  componentProps: ({company}) => ({
    children: (
      <HighlightedGrade
        score={integer(company?.Financials?.HaloFinancialFactorRating || 0)}
        showScore
      />
    ),
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Financials?.HaloFinancialFactorRating || 0) -
      Number(b?.Financials?.HaloFinancialFactorRating || 0)
    );
  },
});
