// date lib
import formatISODate from '../../date/formatters/formatISO.formatter.date';

// number lib
import differenceInPercentage from '../../number/lib/differenceInPercentage.lib.number';

const liabilitiesDataMap = (company) => {
  const localFinancialsType = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]?.[0]?.type;

  const {categories, entries} = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]
    .filter(({type}) => type === localFinancialsType)
    .reduce(
      (combined, current, currentIndex, originalArray) => {
        const categories = [
          ...combined.categories,
          {
            key: current.yearEndDate,
            label: formatISODate({
              date: current.yearEndDate,
              dateFormat: 'MM/dd/yy',
            }),
          },
        ];
        const nextItem = originalArray?.[currentIndex + 1];
        const nextItemCategory = `${current.yearEndDate}${nextItem?.yearEndDate}`;
        const updatedCategories = !!nextItem
          ? [
              ...categories,
              {
                key: nextItemCategory,
                label: '',
              },
            ]
          : categories;

        const entries = Object.entries(current?.balanceSheet || {}).reduce(
          (combinedEntries, [entryKey, entryValue]) => {
            if (
              ![
                'accountsPayable',
                'shortTermDebt',
                'currentPortionOfLongTermDebt',
                'taxesPayable',
                'otherCurrentLiabilities',
                'totalCurrentLiabilities',
                'longTermDebt',
                'longTermConvertibleDebt',
                'leaseObligation',
                'totalLongTermInterestBearingDebt',
                'minorityInterest',
                'deferredTaxes',
                'provisions',
                'otherLongTermLiabilities',
                'totalLiabilitiesAndDebt',
                'redeemablePreferredShares',
                'preferredShares',
                'commonStockOrShares',
                'participationShares',
                'shareCapital',
                'additionPaidInCapital',
                'legalOrUntaxedOrSpecialReserves',
                'shareholdersReserve',
                'retainedEarnings',
                'treasuryStock',
                'otherEquity',
                'minorityInterestInShareholdersEquity',
                'totalShareholdersEquity',
                'totalLiabilitiesAndEquity',
              ].includes(entryKey)
            )
              return combinedEntries;
            const entry = [...combined.entries].find(
              (existingEntry) => existingEntry.entry.value === entryKey
            ) || {
              entry: {
                value: entryKey,
                type: 'key',
              },
            };
            entry[current.yearEndDate] = {
              value: entryValue,
              type: 'currency',
            };
            if (!!nextItem) {
              const currentEntryValue = current.balanceSheet[entryKey] || 0;
              const nextEntryValue = nextItem.balanceSheet[entryKey] || 0;
              entry[nextItemCategory] = {
                value: differenceInPercentage(
                  nextEntryValue,
                  currentEntryValue
                ),
                type: 'trendInPercentage',
              };
            }
            return [...combinedEntries, entry];
          },
          []
        );

        return {
          categories: updatedCategories,
          entries,
        };
      },
      {
        categories: [],
        entries: [],
      }
    );

  const updatedCategories = [
    {
      key: 'entry',
      label: '',
    },
    ...categories.reverse(),
  ];
  const updatedEntries = [...entries].filter((entry) =>
    Object.entries(entry).every(
      ([key, value]) => key === 'entry' || !!value?.value
    )
  );
  return {
    categories: updatedCategories,
    entries: updatedEntries,
  };
};

export default liabilitiesDataMap;
