import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  z-index: -10000000000;
  top: -100%;
  left: -100%;
`;

export default Container;
