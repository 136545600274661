// haloGpt assets
import iconUri from '../../assets/halogptIcon.png';

// local components
import Img from './components/Img';

// react
import React from 'react';

const HaloGptIcon = ({...props}) => <Img src={iconUri} {...props} />;

export default HaloGptIcon;
