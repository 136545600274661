import styled from 'styled-components';

const OptionsContainer = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  flex: 1;
`;

export default OptionsContainer;
