// date
import {format, parse} from 'date-fns';

// date lib
import orderByDate from '../../../../date/lib/orderByDate.lib.date';

const getChartData = (articles) => {
  const chartData = Object.entries(articles).map(([date, numberOfArticles]) => {
    return {
      name: format(parse(date, 'yyyy-MM-dd', new Date()), 'MMM d'),
      date,
      y: numberOfArticles,
    };
  });

  return orderByDate({
    list: chartData,
    dateAttribute: 'date',
    ascending: true,
  });
};

export default getChartData;
