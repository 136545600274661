// date lib
import formatISODate from '../../date/formatters/formatISO.formatter.date';

// meltwater lib
import getFilteredNewsArticles from '../lib/getFilteredNewsArticles.lib.meltwater';

const generateNewsAnalyticsService = ({category, news, sentiment}) => {
  const articles = getFilteredNewsArticles({
    category,
    news,
    sentiment,
  });

  const numberOfArticles = articles.length;
  const documentsPerDay = [...articles].reduce((combined, article) => {
    const articleDate = formatISODate({
      date: article.published_date,
      dateFormat: 'yyyy-MM-dd',
    });
    return {
      ...combined,
      [articleDate]: (combined?.[articleDate] || 0) + 1,
    };
  }, {});

  const keyPhrases = [...articles].reduce(
    (combined, article) =>
      [...article.keyphrases].reduce(
        (combinedPhrases, keyPhrase) => ({
          ...combinedPhrases,
          [keyPhrase]: (combinedPhrases?.[keyPhrase] || 0) + 1,
        }),
        {...combined}
      ),
    {}
  );

  const sentimentCount = ['negative', 'neutral', 'positive'].reduce(
    (combined, currentSentiment) => ({
      ...combined,
      [currentSentiment]: [...articles].filter(
        (article) => article.sentiment === currentSentiment
      ).length,
    }),
    {}
  );

  const documentsPerCountry = [...articles].reduce(
    (combined, article) => ({
      ...combined,
      [article.country_code]: (combined?.[article.country_code] || 0) + 1,
    }),
    {}
  );

  const documentsPerLanguage = [...articles].reduce(
    (combined, article) => ({
      ...combined,
      [article.language_code]: (combined?.[article.language_code] || 0) + 1,
    }),
    {}
  );

  return {
    numberOfArticles,
    documentsPerDay,
    keyPhrases,
    sentimentCount,
    documentsPerCountry,
    documentsPerLanguage,
  };
};

export default generateNewsAnalyticsService;
