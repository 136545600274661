import styled from 'styled-components';

const ActivityContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export default ActivityContent;
