import styled from 'styled-components';

// layout colors
import brightGreen300Color from '../../../../layout/colors/brightGreen300.color.layout';
import brightRed300Color from '../../../../layout/colors/brightRed300.color.layout';

const Trend = styled.div`
  display: inline-block;
  font-weight: 700;
  color: ${({increasing}) =>
    increasing ? brightGreen300Color : brightRed300Color};
`;

export default Trend;
