import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
// import grey300Color from '../../../../layout/colors/grey300.color.layout';

const Message = styled.div`
  width: 100%;
  ${'' /* padding: 8px 12px; */}
  ${'' /* font-size: 11px; */}
  ${'' /* border-radius: 16px; */}
  ${'' /* background: ${grey300Color}; */}
  color: ${blackColor};
`;

export default Message;
