// ant components
import {Col as Column, Row} from 'antd';

// news components
import MeltwaterNewsAnalytics from '../MeltwaterNewsAnalytics/MeltwaterNewsAnalytics';
import MeltwaterNewsList from '../MeltwaterNewsList/MeltwaterNewsList';

// react
import React from 'react';

const MeltwaterNewsCombinedLayout = () => (
  <Row gutter={[20, 20]}>
    <Column span={15}>
      <MeltwaterNewsList />
    </Column>
    <Column span={9}>
      <MeltwaterNewsAnalytics />
    </Column>
  </Row>
);

export default MeltwaterNewsCombinedLayout;
