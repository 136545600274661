// ant components
import {Col, Row, Select} from 'antd';

// date formatters
import formatISODate from '../../../date/formatters/formatISO.formatter.date';

// local components
import Activities from './components/Activities';
import Activity from './components/Activity';
import ActivityContent from './components/ActivityContent';
import ActivityImage from './components/ActivityImage';
import ActivityImageContainer from './components/ActivityImageContainer';
import Container from './components/Container';
import Date from './components/Date';

// local lib
import generateArticleTypes from './lib/generateArticleTypes.lib';

// meltwater lib
import getFilteredNewsArticles from '../../lib/getFilteredNewsArticles.lib.meltwater';

// meltwater redux actions
import {setGeneralMeltwaterData as setGeneralMeltwaterDataAction} from '../../redux/reducer.redux.meltwater';

// react
import React from 'react';

// redux
import {useDispatch, useSelector} from 'react-redux';

const MeltwaterNewsList = () => {
  const {category, news, sentiment} = useSelector((state) => ({
    category: state.news.category,
    news: state.news.news,
    sentiment: state.news.sentiment,
  }));
  const dispatch = useDispatch();

  const filteredNewsArticles = getFilteredNewsArticles({
    category,
    news,
    sentiment,
  });

  const availableArticleTypes = generateArticleTypes({
    articles: news,
    replacements: {
      cybersecurity: 'Cyber Security',
      esg_csr: 'ESG & CSR',
      ma: 'Merger & Acquisition',
      negative_financial_sentiment: 'Financials',
      negative_financial_events: 'Events',
    },
  });

  return (
    <Container>
      <Row gutter={[20, 20]}>
        <Col className="hide-on-print">
          <Select
            value={category}
            style={{width: '260px'}}
            onChange={(value) =>
              dispatch(setGeneralMeltwaterDataAction({category: value}))
            }
            options={[
              {
                value: 'all',
                label: (
                  <div>
                    <i className="mdi mdi-view-list-outline" /> All Articles
                  </div>
                ),
              },
              ...[...availableArticleTypes].map((articleType) => ({
                value: articleType.key,
                label: (
                  <div>
                    <i className={`mdi mdi-${articleType.icon}`} />{' '}
                    {articleType.label}
                  </div>
                ),
              })),
            ]}
          />
        </Col>

        <Col className="hide-on-print">
          <Select
            value={sentiment}
            style={{width: '260px'}}
            onChange={(value) =>
              dispatch(setGeneralMeltwaterDataAction({sentiment: value}))
            }
            options={[
              {
                value: 'all',
                label: 'All Sentiments',
              },
              {
                value: 'negative',
                label: 'Negative',
              },
              {
                value: 'positive',
                label: 'Positive',
              },
              {
                value: 'neutral',
                label: 'Neutral',
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Activities>
            {!filteredNewsArticles.length && (
              <Activity>
                <div>No News Articles Found</div>
              </Activity>
            )}
            {filteredNewsArticles.map((article) => (
              <Activity key={article.id} href={article.url} target="_blank">
                <ActivityImageContainer>
                  {!!article?.image && <ActivityImage src={article?.image} />}
                </ActivityImageContainer>
                <ActivityContent>
                  <div>{article?.title || '-'}</div>
                  {!!article?.hit_sentence && (
                    <Date>{article?.hit_sentence}</Date>
                  )}
                  {!!article?.published_date && (
                    <Date>
                      {formatISODate({
                        date: article?.published_date,
                        dateFormat: 'MM/dd/yyyy hh:mm a',
                      })}
                    </Date>
                  )}
                </ActivityContent>
              </Activity>
            ))}
          </Activities>
        </Col>
      </Row>
    </Container>
  );
};

export default MeltwaterNewsList;
