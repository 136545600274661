const loadAndAttachAsyncJsonToObject = async ({
  company = {},
  dataObject = {},
} = {}) => {
  if (!dataObject) return company;

  const loadedListOfData = await Promise.all(
    Object.entries(dataObject).map(async ([dataKey, apiCall] = {}) => {
      try {
        const loadedData = await apiCall;
        return {dataKey, data: loadedData || null};
      } catch (error) {
        return {dataKey, data: null};
      }
    })
  );

  const parsedCompany = [...loadedListOfData].reduce(
    (combined, {dataKey, data}) => ({...combined, [dataKey]: data}),
    {...company}
  );

  return parsedCompany;
};

export default loadAndAttachAsyncJsonToObject;
