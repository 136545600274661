// financial components
import FinancialStatementsCard from '../FinancialStatementsCard/FinancialStatementsCard';
import FinancialStatementsKeyPerformanceIndicatorsCard from '../FinancialStatementsKeyPerformanceIndicatorsCard/FinancialStatementsKeyPerformanceIndicatorsCard';

// layout components
import Tabs from '../../../layout/components/Tabs/Tabs';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialStatements = ({haloGptResponses, printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const tabs = [
    {
      key: 'incomeStatement',
      label: 'Income Statement',
      haloGptMessage: 'Explain Income Statement',
    },
    {
      key: 'assets',
      label: 'Assets',
      haloGptMessage: 'Explain Assets',
    },
    {
      key: 'liabilities',
      label: 'Liabilities',
      haloGptMessage: 'Explain Liabilities',
    },
    {
      key: 'cashFlow',
      label: 'Cashflow',
      haloGptMessage: 'Explain Cash Flow',
    },
    {
      key: 'profitabilityRatios',
      label: 'Profitability Ratios',
      haloGptMessage: 'Explain Profitability Ratios',
    },
    {
      key: 'assetUtilization',
      label: 'Asset Utilization',
      haloGptMessage: 'Explain Asset Utilization',
    },
    {
      key: 'liquidityRatios',
      label: 'Liquidity Ratios',
      haloGptMessage: 'Explain Liquidity Ratios',
    },
    {
      key: 'capitalStructure',
      label: 'Capital Structure',
      haloGptMessage: 'Explain Capital Structure',
    },
    {
      key: 'strategicRiskItems',
      label: 'Strategic Risk Items',
      haloGptMessage: 'Explain Strategic Risk Items',
    },
    {
      key: 'growthRates',
      label: 'Growth Rates',
      haloGptMessage: 'Explain Growth Rates',
    },
    {
      key: 'Key Performance Indicators',
      label: 'Key Performance Indicators',
      children: (
        <FinancialStatementsKeyPerformanceIndicatorsCard
          haloGptMessage="Explain Key Performance Indicators"
          haloGptResponse={
            haloGptResponses?.['Explain Key Performance Indicators']
          }
          printView={printView}
        />
      ),
    },
  ]
    .filter((tab) => !!tab)
    .map((tab) => ({
      ...tab,
      children: tab.children || (
        <FinancialStatementsCard
          categories={company.financials.financials[tab.key].categories}
          currency={company.financials.currency}
          entries={company.financials.financials[tab.key].entries}
          haloGptMessage={tab.haloGptMessage}
          haloGptResponse={haloGptResponses?.[tab?.haloGptMessage] || null}
          printView={printView}
          title={tab.label}
        />
      ),
    }));

  return <Tabs tabs={tabs} printView={printView} />;
};

FinancialStatements.propTypes = {
  haloGptResponses: PropTypes.object,
  printView: PropTypes.bool,
};

export default FinancialStatements;
