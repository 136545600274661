// ant components
import {Tooltip} from 'antd';

// haloGpt events
import answerHaloGptQueryEvent from '../../../haloGpt/events/answerQuery.event.haloGpt';

// local components
import Container from './components/Container';
import Content from './components/Content';
import ContentBox from './components/ContentBox';
import Explain from './components/Explain';
import HaloGptIcon from './components/HaloGptIcon';
import Header from './components/Header';
import Message from './components/Message';
import MessageContainer from './components/MessageContainer';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const StatementBlock = ({
  children,
  displayHaloGptResponse,
  haloGptMessage,
  haloGptResponse,
  title,
  tooltip,
  padding = false,
}) => {
  return (
    <Container>
      <Header>
        {!!title ? (
          !!tooltip ? (
            <Tooltip title={tooltip}>
              <Title>
                {title} <i className="mdi mdi-information" />
              </Title>
            </Tooltip>
          ) : (
            <Title>{title}</Title>
          )
        ) : null}
        {!!haloGptMessage && (
          <Explain
            onClick={() => answerHaloGptQueryEvent.publish(haloGptMessage)}
          >
            <HaloGptIcon />
            Explain
          </Explain>
        )}
      </Header>
      {!!children && (
        <ContentBox>
          {displayHaloGptResponse && !!haloGptResponse && (
            <MessageContainer>
              <Message>{haloGptResponse}</Message>
            </MessageContainer>
          )}
          <Content padding={padding}>{children}</Content>
        </ContentBox>
      )}
    </Container>
  );
};

StatementBlock.propTypes = {
  children: PropTypes.node,
  displayHaloGptResponse: PropTypes.bool,
  haloGptMessage: PropTypes.string,
  haloGptResponse: PropTypes.string,
  padding: PropTypes.bool,
  title: PropTypes.node,
  tooltip: PropTypes.node,
};

export default StatementBlock;
