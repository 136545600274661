// charts
import Highcharts from 'highcharts';

// layout colors
import blueGrey200Color from '../../../layout/colors/blueGrey200.color.layout';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const CountryBreakdownCard = ({countries = {}}) => {
  const id = v4();

  const countriesList = Object.entries(countries)
    .map(([country, numberOfArticles]) => ({
      name: country.toUpperCase(),
      y: numberOfArticles,
    }))
    .sort((a, b) => b.y - a.y);

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'bar',
        height: `${countriesList.length * 40 + 50}px`,
      },
      colors: [blueGrey200Color],
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        tickInterval: 1,
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
        },
      },
      series: [
        {
          name: 'Number of articles',
          data: countriesList,
        },
      ],
    });
    // eslint-disable-next-line
  }, [countriesList]);

  return (
    <StatementBlock title="Number of articles per country">
      <div id={id} />
    </StatementBlock>
  );
};

CountryBreakdownCard.propTypes = {
  countries: PropTypes.object,
};

export default CountryBreakdownCard;
