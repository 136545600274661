// charts
import Highcharts from 'highcharts';

// layout colors
import grey400Color from '../../../layout/colors/grey400.color.layout';
import brightGreen300Color from '../../../layout/colors/brightGreen300.color.layout';
import brightRed300Color from '../../../layout/colors/brightRed300.color.layout';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const SentimentChartCard = ({negative = 0, neutral = 0, positive = 0}) => {
  const id = v4();

  const total = negative + neutral + positive;
  const negativePercentage = (negative / total) * 100;
  const neutralPercentage = (neutral / total) * 100;
  const positivePercentage = (positive / total) * 100;

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
        height: '240px',
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '14px',
              fontWeight: 400,
            },
            format: '<b>{point.name}</b><br/>{point.y:,.0f}%',
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return `<b>${this.point.name}</b><br/>${integer(
            this.point.y
          )}%<br/>Number of documents: ${
            this.point?.data?.numberOfDocuments || 0
          }<div>`;
        },
      },
      series: [
        {
          name: 'Number of articles',
          data: [
            {
              name: 'Negative',
              y: negativePercentage,
              color: brightRed300Color,
              data: {
                numberOfDocuments: negative,
              },
            },
            {
              name: 'Neutral',
              y: neutralPercentage,
              color: grey400Color,
              data: {
                numberOfDocuments: neutral,
              },
            },
            {
              name: 'Positive',
              y: positivePercentage,
              color: brightGreen300Color,
              data: {
                numberOfDocuments: positive,
              },
            },
          ],
        },
      ],
    });
    // eslint-disable-next-line
  }, [negative, neutral, positive]);

  return (
    <StatementBlock padding title="Sentiment">
      <div id={id} />
    </StatementBlock>
  );
};

SentimentChartCard.propTypes = {
  negative: PropTypes.number,
  neutral: PropTypes.number,
  positive: PropTypes.number,
};

export default SentimentChartCard;
