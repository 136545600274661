// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// haloGpt api
import askHaloGptApi from '../../api/ask.api.haloGpt';

// haloGpt components
import PageHaloGpt from '../../components/PageHaloGpt/PageHaloGpt';

// haloGpt events
import answerHaloGptQueryEvent from '../../events/answerQuery.event.haloGpt';

// haloGpt lib
import generateParametersForExplainApi from '../../lib/generateParametersForExplainApi.lib.haloGpt';
import prepareMessageQueriesForChatGPTFromFlatQuestionFlow from '../../lib/prepareMessageQueriesForChatGPTFromFlatQuestionFlow.lib.haloGpt';
import prepareQuestionFlow from '../../lib/prepareQuestionFlow.lib.haloGpt';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, createRef} from 'react';

// uuid
import {v4} from 'uuid';

class PageHaloGptContainer extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    questionFlow: PropTypes.object.isRequired,
    scope: PropTypes.string,
    subscribe: PropTypes.func,
  };

  static defaultTypes = {
    data: {},
  };

  state = {
    initializing: true,
    loading: false,
    message: '',
    messages: [],
    options: [],
    queries: [],
    selection: [],
    visible: false,
  };

  questionFlow = {};

  scrollerDom = createRef();

  componentDidMount() {
    this.initialize();
    this.props.subscribe(answerHaloGptQueryEvent.subscribe(this.answerQuery));
  }

  initialize = () => {
    const {questionFlow} = this.props;

    this.questionFlow = prepareQuestionFlow(questionFlow);

    const messages = [
      !!questionFlow?.welcomeMessage && {
        id: v4(),
        messages: [
          {id: 'welcomeMessage', message: questionFlow.welcomeMessage},
        ],
        user: false,
        action: false,
      },
    ].filter((message) => !!message);
    this.setState({
      initializing: false,
      messages,
      options: this.getNextUserOptions(),
    });
  };

  open = () => {
    this.setState({visible: true}, this.scrollToTheBottom);
  };

  close = () => {
    this.setState({visible: false});
  };

  querySelected = async (query, {outsideMessage = false} = {}) => {
    const {data} = this.props;
    const {loading, messages} = this.state;
    if (loading) return;

    const userSelectedMessages = [
      ...messages,
      {
        id: v4(),
        messages: [query],
        user: true,
        action: false,
      },
    ];
    this.setState(
      {
        loading: true,
        message: outsideMessage ? '' : this.state.message,
        messages: userSelectedMessages,
      },
      this.scrollToTheBottom
    );

    try {
      const messageQuery = outsideMessage
        ? query.queries
        : prepareMessageQueriesForChatGPTFromFlatQuestionFlow({
            data,
            messageId: query.id,
            questionFlow: this.questionFlow.flat,
          });
      const response = await askHaloGptApi(
        generateParametersForExplainApi({
          messages: messageQuery,
          ...(this.questionFlow?.flat?.settings || {}),
        })
      );
      const content = response?.message?.choices?.[0]?.message?.content || '';
      const newMessages = [
        ...userSelectedMessages,
        {
          id: v4(),
          messages: [{id: v4(), message: content}],
          user: false,
          action: false,
        },
      ];

      const options = outsideMessage
        ? this.state.options
        : !!query?.messages?.length
        ? [...query.messages]
        : this.getNextUserOptions();

      this.setState(
        {
          loading: false,
          messages: newMessages,
          options,
          queries: messageQuery,
        },
        this.scrollToTheBottom
      );
    } catch (error) {
      const newMessages = [
        ...userSelectedMessages,
        {
          id: v4(),
          messages: [
            {
              id: v4(),
              message:
                'I could not generate any meaningful response. Please try again.',
            },
          ],
          user: false,
          action: false,
        },
      ];
      this.setState(
        {loading: false, messages: newMessages},
        this.scrollToTheBottom
      );
    }
  };

  answerQuery = (message) => {
    const {questionFlow} = this;
    const existingMessage = [...(questionFlow?.three?.messages || [])].find(
      (msg) => msg.message === message
    );
    if (!existingMessage) return;
    this.querySelected(existingMessage);
  };

  setMessage = (message) => {
    if (this.state.loading) return;
    this.setState({message});
  };

  postMessage = (e) => {
    e?.preventDefault?.();
    // const {data} = this.props;
    const {message, queries: previousQueries} = this.state;
    if (!message.trim().length) return;
    // const previousQueries = [...messages]
    //   .map((existingMessageObject) =>
    //     existingMessageObject.messages
    //       .map((existingMessage) => existingMessage?.queries || [])
    //       .flat()
    //   )
    //   .flat();
    const includeSystemMessage = ![...previousQueries].find(
      (query) => query.role === 'system'
    );
    const queries = [
      ...(includeSystemMessage
        ? this.questionFlow.flat?.systemQueries || []
        : []),
      ...previousQueries,
      {
        role: 'user',
        content: message,
      },
    ];
    this.querySelected({id: v4(), message, queries}, {outsideMessage: true});
  };

  getNextUserOptions = (selection = this.state.selection) => {
    const {questionFlow} = this;
    const messages = [...selection].reduce(
      (message, messageId) =>
        message?.[messageId]?.messages || message?.messages || [],
      [...questionFlow.three.messages]
    );
    return messages;
  };

  scrollToTheBottom = () => {
    // if (!this.state.visible) return;
    this.scrollerDom.current.scrollTop = this.scrollerDom.current.scrollHeight;
  };

  showFreeFormInput = () => {
    const {messages} = this.state;
    return !![...messages].find(({user}) => user);
  };

  render() {
    const {loading, message, messages, options, visible} = this.state;
    return (
      <PageHaloGpt
        loading={loading}
        message={message}
        messages={messages}
        onHideModal={this.close}
        onMessage={this.setMessage}
        onPostMessage={this.postMessage}
        onQuerySelect={this.querySelected}
        onShowModal={this.open}
        options={options}
        scrollerDom={this.scrollerDom}
        showFreeFormInput={this.showFreeFormInput()}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(PageHaloGptContainer);
