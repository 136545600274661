const generateArticleTypes = ({articles, replacements = {}} = {}) =>
  [...articles]
    .reduce((combined, {categories}) => {
      const uniqueCategories = [...(categories || [])].filter(
        (category) => !combined.includes(category)
      );
      return [...combined, ...uniqueCategories];
    }, [])
    .map((category) => {
      const replacementLabel =
        replacements?.[category] ||
        category
          .split('_')
          .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
          .join(' ');
      return {
        key: category,
        label: replacementLabel,
        icon: 'newspaper',
      };
    });

export default generateArticleTypes;
