import styled from 'styled-components';

// constants
const SIZE = '100px';

const ActivityImageContainer = styled.div`
  width: ${SIZE};
  ${'' /* height: ${SIZE}; */}
  max-width: ${SIZE};
  max-height: ${SIZE};
  min-width: ${SIZE};
  ${'' /* min-height: ${SIZE}; */}
`;

export default ActivityImageContainer;
