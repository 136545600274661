// haloGpt components
import HaloGptIcon from '../../../../haloGpt/components/HaloGptIcon/HaloGptIcon';

// styled components
import styled from 'styled-components';

const Icon = styled(HaloGptIcon)`
  display: block;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
`;

export default Icon;
