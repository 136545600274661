// company redux actions
import {setGeneralCompanyData as setGeneralCompanyDataAction} from '../../../company/redux/reducer.redux.company';

// crunchBase api
import loadCrunchBaseNewsApi from '../../../crunchBase/api/loadNews.api.crunchBase';

// data services
import loadAndAttachAsyncJsonToObjectService from '../../../data/services/loadAndAttachAsyncJsonToObject.service.data';

// financial components
import NewsLayout from '../../components/CrunchbaseNewsLayout/CrunchbaseNewsLayout';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propType
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class CompanyNewsContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.object,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {company, dispatch} = this.props;

    if (company?.loadedNewsPage) return this.setState({loading: false});

    try {
      const companyId = getCompanyId(company);
      const companyWithLoadedData = await loadAndAttachAsyncJsonToObjectService(
        {
          company,
          dataObject: {
            crunchbaseNews: loadCrunchBaseNewsApi(companyId),
          },
        }
      );

      const combinedCompany = {
        ...company,
        ...companyWithLoadedData,
        loadedNewsPage: true,
      };

      dispatch(
        setGeneralCompanyDataAction({loading: false, company: combinedCompany})
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {loading} = this.state;
    return loading ? <FullScreenLoader /> : <NewsLayout />;
  }
}

export default connect((state) => ({company: state.company.company}))(
  CompanyNewsContainer
);
