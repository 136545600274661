// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// financial components
import FinancialsTabs from '../FinancialsTabs/FinancialsTabs';

// financial haloGpt
import chatHaloGptPrompt from '../../haloGpt/chat.haloHpt.financial';

// haloGpt containers
import PageHaloGptContainer from '../../../haloGpt/containers/PageHaloGptContainer/PageHaloGptContainer';
import PrepareHaloGptAnswersContainer from '../../../haloGpt/containers/PrepareHaloGptAnswersContainer/PrepareHaloGptAnswersContainer';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// local lib
import getGptData from './lib/getGptData.lib';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  const haloGptData = getGptData({company});

  const questionFlow = chatHaloGptPrompt({data: company.financials});

  const printButton = ({print, generatingAnswers}) => (
    <Button
      icon={<PrinterOutlined />}
      onClick={print}
      loading={generatingAnswers}
    >
      {generatingAnswers ? 'Preparing Report' : 'Print Report'}
    </Button>
  );

  return (
    <PrepareHaloGptAnswersContainer queries={questionFlow}>
      {({answers, generatingAnswers}) => (
        <Print
          title="Financials Report"
          subtitle={company.CompanyName}
          trigger={({print}) => (
            <PageLayout
              alerts={company?.alerts || []}
              previousPageLabel="Back to Dashboard"
              previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
              sideContent={({goToSection}) => (
                <CompanySideMenuContainer goToSection={goToSection} />
              )}
              subPage={subPage}
              subPages={generateSubPages(company)}
              sectionName="Financials"
              sections={[
                {
                  section: 'Financials',
                  component: <FinancialsTabs haloGptResponses={answers} />,
                  moreButton: printButton({print, generatingAnswers}),
                },
              ].filter((section) => !!section)}
              chat={
                <PageHaloGptContainer
                  data={haloGptData}
                  questionFlow={questionFlow}
                />
              }
            />
          )}
        >
          <PrintLayout>
            <PrintSection title="Rating">
              <FinancialsTabs haloGptResponses={answers} printView />
            </PrintSection>
          </PrintLayout>
        </Print>
      )}
    </PrepareHaloGptAnswersContainer>
  );
};

export default FinancialsLayout;
