// antd
import {Select, Table} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';
import {Row, Column} from '../../../layout/components/Grid/Grid';

// local components
import FilterLabel from './components/FilterLabel';

// local lib
import columns from './lib/columns.lib';
import convertSourcesToOptions from './lib/convertSourcesToOptions.lib';
import extractSources from './lib/extractSources.lib';
import getElements from './lib/getElements.lib';

// react
import React, {useState} from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgSourceDataValuesTable = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const [sourceFilter, setSourceFilter] = useState(null);

  const dataSources = (
    company?.esgInfo?.data_sources_data || company?.esgInfo?.dataSourcesData
  )?.datasourcesall;
  const sources = extractSources({company});
  const elements = getElements({company, sourceFilter});

  return (
    <Card
      title={`${elements.length} Data values from ${dataSources.length} sources`}
    >
      <Row margin>
        <Column size={1 / 4}>
          <FilterLabel>Source Filter</FilterLabel>
          <Select
            style={{width: '100%'}}
            onChange={(source) => setSourceFilter(source)}
            value={sourceFilter}
            options={convertSourcesToOptions(sources)}
            allowClear
          />
        </Column>
      </Row>
      <Table
        columns={columns()}
        dataSource={elements}
        pagination
        rowKey="id"
        size="small"
      />
    </Card>
  );
};

export default EsgSourceDataValuesTable;
