const getTrendIndicator = (chartData) => {
  const [last, secondLast] = [...chartData].reverse();

  const lastValue = last?.y || 0;
  const secondLastValue = secondLast?.y || 0;

  if (lastValue === secondLastValue) return null;
  return lastValue < secondLastValue ? 'decreasing' : 'increasing';
};

export default getTrendIndicator;
