// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company components
import CompanyKeyFinancialsSection from '../CompanyKeyFinancialsSection/CompanyKeyFinancialsSection';
import FinancialsActivityModuleSection from '../FinancialsActivityModuleSection/FinancialsActivityModuleSection';
import FinancialsBalanceSheetSection from '../FinancialsBalanceSheetSection/FinancialsBalanceSheetSection';
import FinancialsCashFlowSummarySection from '../FinancialsCashFlowSummarySection/FinancialsCashFlowSummarySection';
import FinancialsDuPontROASection from '../FinancialsDuPontROASection/FinancialsDuPontROASection';
import FinancialsKeyDataAndRatiosSection from '../FinancialsKeyDataAndRatiosSection/FinancialsKeyDataAndRatiosSection';
import FinancialsLiquidityModuleSection from '../FinancialsLiquidityModuleSection/FinancialsLiquidityModuleSection';

// company containers
import CompanySideMenuContainer from '../../containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../pages/SuppliersDashboardPage/route';

// financial constants
import FINANCIAL_HALO_GPT_CONVERSATION from '../../../financial/constants/financialHaloGptConversation.constants.financial';

// financial components
import CBInsightsFinancialSection from '../../../financial/components/CBInsightsFinancialSection/CBInsightsFinancialSection';
import CreditSafeSection from '../../../financial/components/CreditSafeSection/CreditSafeSection';
import ExtendedPrivateCompanyFinancialsTable from '../../../financial/components/ExtendedPrivateCompanyFinancialsTable/ExtendedPrivateCompanyFinancialsTable';
import FinancialOverviewCharts from '../../../financial/components/FinancialOverviewCharts/FinancialOverviewCharts';
import PrivateCompanyFinancialsTable from '../../../financial/components/PrivateCompanyFinancialsTable/PrivateCompanyFinancialsTable';

// haloGpt containers
import PageHaloGptContainer from '../../../haloGpt/containers/PageHaloGptContainer/PageHaloGptContainer';
import PrepareHaloGptAnswersContainer from '../../../haloGpt/containers/PrepareHaloGptAnswersContainer/PrepareHaloGptAnswersContainer';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// local lib
import generateQuery from './lib/generateQuery.lib';
import getGptData from './lib/getGptData.lib';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsLayoutLegacy = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  const haloGptData = getGptData({company});

  const questionFlow = FINANCIAL_HALO_GPT_CONVERSATION(haloGptData, {
    privateFinancials:
      !!haloGptData?.financialsOverview?.length ||
      !!company.extendedPrivateFinancials,
    publicFinancials: !!company.historicalFinancials,
    publicSections: !!company?.historicalFinancials
      ? Object.entries(company.historicalFinancials).reduce(
          (combined, [key, data]) => (!!data ? [...combined, key] : combined),
          []
        )
      : [],
  });

  const printButton = ({print, generatingAnswers}) => (
    <Button
      icon={<PrinterOutlined />}
      onClick={print}
      loading={generatingAnswers}
    >
      {generatingAnswers ? 'Preparing Report' : 'Print Report'}
    </Button>
  );

  const displayCbInsights =
    !!company?.cbInsights &&
    Object.entries(company?.cbInsights)
      .map(([key, value]) => value)
      .filter((value) => !!value).length > 0;

  const hideMainFinancials = false;

  return (
    <PrepareHaloGptAnswersContainer queries={questionFlow}>
      {({answers, generatingAnswers}) => (
        <Print
          title="Financials Report"
          subtitle={company.CompanyName}
          trigger={({print}) => (
            <PageLayout
              alerts={company?.alerts || []}
              previousPageLabel="Back to Dashboard"
              previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
              sideContent={({goToSection}) => (
                <CompanySideMenuContainer goToSection={goToSection} />
              )}
              subPage={subPage}
              subPages={generateSubPages(company)}
              sectionName="Financials"
              sections={[
                {
                  section: 'Rating',
                  component: <FinancialOverviewCharts />,
                  moreButton: printButton({print, generatingAnswers}),
                },
                !!company?.creditSafe && {
                  section: 'Extended Details',
                  component: <CreditSafeSection />,
                },
                !hideMainFinancials && {
                  section: 'Financial Risk',
                  component: <CompanyKeyFinancialsSection />,
                },
                ...(hideMainFinancials
                  ? []
                  : !!company.extendedPrivateFinancials
                  ? [
                      {
                        section: 'Financials Overview',
                        component: <ExtendedPrivateCompanyFinancialsTable />,
                        haloGpt: generateQuery({
                          message: 'Explain Financials Overview',
                          questionFlow,
                        }),
                      },
                    ]
                  : !!company.PrivateFinancialData
                  ? [
                      {
                        section: 'Financials Overview',
                        component: <PrivateCompanyFinancialsTable />,
                        haloGpt: generateQuery({
                          message: 'Explain Financials Overview',
                          questionFlow,
                        }),
                      },
                    ]
                  : []),
                ...(hideMainFinancials
                  ? []
                  : !!company?.historicalFinancials
                  ? [
                      !!company?.historicalFinancials?.keyDataAndRatios && {
                        section: 'Key Data and Ratios',
                        component: <FinancialsKeyDataAndRatiosSection />,
                        haloGpt: generateQuery({
                          message: 'Explain Key Data and Ratios',
                          questionFlow,
                        }),
                      },
                      !!company?.historicalFinancials?.cashFlowSummary && {
                        section: 'Cash Flow Summary',
                        component: <FinancialsCashFlowSummarySection />,

                        haloGpt: generateQuery({
                          message: 'Explain Cash Flow Summary',
                          questionFlow,
                        }),
                      },
                      !!company?.historicalFinancials?.balanceSheet && {
                        section: 'Balance Sheet',
                        component: <FinancialsBalanceSheetSection />,
                        haloGpt: generateQuery({
                          message: 'Explain Balance Sheet',
                          questionFlow,
                        }),
                      },
                      !!company?.historicalFinancials?.liquidityModule && {
                        section: 'Liquidity Module',
                        component: <FinancialsLiquidityModuleSection />,
                        haloGpt: generateQuery({
                          message: 'Explain Liquidity Module',
                          questionFlow,
                        }),
                      },
                      !!company?.historicalFinancials?.activityModule && {
                        section: 'Activity Module',
                        component: <FinancialsActivityModuleSection />,
                        haloGpt: generateQuery({
                          message: 'Explain Activity Module',
                          questionFlow,
                        }),
                      },
                      !!company?.historicalFinancials?.duPontROA && {
                        section: 'DuPont ROA Analysis',
                        component: <FinancialsDuPontROASection />,
                        haloGpt: generateQuery({
                          message: 'Explain DuPont ROA Analysis',
                          questionFlow,
                        }),
                      },
                    ]
                  : []),
                displayCbInsights && {
                  section: 'Details',
                  component: <CBInsightsFinancialSection />,
                },
              ].filter((section) => !!section)}
              chat={
                <PageHaloGptContainer
                  data={haloGptData}
                  questionFlow={questionFlow}
                />
              }
            />
          )}
        >
          <PrintLayout>
            <PrintSection title="Rating">
              <FinancialOverviewCharts print />
            </PrintSection>
            {!!company?.creditSafe && (
              <PrintSection title="Highlights">
                <CreditSafeSection />
              </PrintSection>
            )}
            {hideMainFinancials && (
              <PrintSection title="Financial Risk">
                <CompanyKeyFinancialsSection />
              </PrintSection>
            )}
            {hideMainFinancials ? null : !!company.extendedPrivateFinancials ? (
              <PrintSection
                title="Financials Overview"
                explanation={answers?.['Explain Financials Overview']}
              >
                <ExtendedPrivateCompanyFinancialsTable />
              </PrintSection>
            ) : !!company.PrivateFinancialData ? (
              <PrintSection
                title="Financials Overview"
                explanation={answers?.['Explain Financials Overview']}
              >
                <PrivateCompanyFinancialsTable />
              </PrintSection>
            ) : null}
            {!hideMainFinancials && !!company?.historicalFinancials && (
              <Fragment>
                {!!company?.historicalFinancials?.keyDataAndRatios && (
                  <PrintSection
                    title="Key Data and Ratios"
                    explanation={answers?.['Explain Key Data and Ratios']}
                  >
                    <FinancialsKeyDataAndRatiosSection />
                  </PrintSection>
                )}
                {!!company?.historicalFinancials?.cashFlowSummary && (
                  <PrintSection
                    title="Cash Flow Summary"
                    explanation={answers?.['Explain Cash Flow Summary']}
                  >
                    <FinancialsCashFlowSummarySection />
                  </PrintSection>
                )}
                {!!company?.historicalFinancials?.balanceSheet && (
                  <PrintSection
                    title="Balance Sheet"
                    explanation={answers?.['Explain Balance Sheet']}
                  >
                    <FinancialsBalanceSheetSection />
                  </PrintSection>
                )}
                {!!company?.historicalFinancials?.liquidityModule && (
                  <PrintSection
                    title="Liquidity Module"
                    explanation={answers?.['Explain Liquidity Module']}
                  >
                    <FinancialsLiquidityModuleSection print />
                  </PrintSection>
                )}
                {!!company?.historicalFinancials?.activityModule && (
                  <PrintSection
                    title="Activity Module"
                    explanation={answers?.['Explain Activity Module']}
                  >
                    <FinancialsActivityModuleSection print />
                  </PrintSection>
                )}
                {!!company?.historicalFinancials?.duPontROA && (
                  <PrintSection
                    title="DuPont ROA Analysis"
                    explanation={answers?.['Explain DuPont ROA Analysis']}
                  >
                    <FinancialsDuPontROASection />
                  </PrintSection>
                )}
              </Fragment>
            )}
            {displayCbInsights && (
              <PrintSection title="Details">
                <CBInsightsFinancialSection />
              </PrintSection>
            )}
          </PrintLayout>
        </Print>
      )}
    </PrepareHaloGptAnswersContainer>
  );
};

export default FinancialsLayoutLegacy;
