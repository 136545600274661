import styled from 'styled-components';

// layout colors
import blueGrey400Color from '../../../../layout/colors/blueGrey400.color.layout';
import green400Color from '../../../colors/green400.color.layout';
import whiteColor from '../../../colors/white.color.layout';

const Explain = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  padding: 3px 6px;
  color: ${whiteColor};
  background-color: ${green400Color};
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;

  &:hover {
    background-color: ${blueGrey400Color};
  }

  @media print {
    display: none;
  }
`;

export default Explain;
