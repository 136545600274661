// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const StrategicScoresChartCard = ({
  data = [],
  dataDescriptions = null,
  info = null,
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
        height: '360px',
        options3d: {
          enabled: true,
          alpha: 45,
        },
      },
      title: {
        text: 'Halo Score Distribution Across Key Risk Areas',
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '16px',
              fontWeight: 400,
            },
            format: '<b>{point.name}</b><br/>{point.y:,.0f}%',
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return `<b>${this.point.name}</b><br/>${integer(this.point.y)}%<div>${
            dataDescriptions?.[this.point.name] || ''
          }</div>`;
        },
      },
      series: [
        {
          name: 'Score',
          data,
        },
      ],
    });
    // eslint-disable-next-line
  }, [data, dataDescriptions]);

  return (
    <Card infoTooltip={info} noPadding>
      <div id={id} />
    </Card>
  );
};

StrategicScoresChartCard.propTypes = {
  data: PropTypes.array,
  dataDescriptions: PropTypes.object,
  info: PropTypes.node,
};

export default StrategicScoresChartCard;
