const getFilteredNewsArticles = ({news, category, sentiment}) => {
  const articleFilters = [
    category !== 'all' &&
      ((article) => (article.categories || []).includes(category)),
    sentiment !== 'all' && ((article) => article.sentiment === sentiment),
  ].filter(Boolean);

  return !!articleFilters.length
    ? [...news].filter((article) =>
        articleFilters.every((filter) => filter(article))
      )
    : news;
};

export default getFilteredNewsArticles;
