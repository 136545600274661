// ant components
import {Col as Column, Row} from 'antd';

// meltwater services
import generateNewsAnalyticsService from '../../services/generateNewsAnalytics.service.meltwater';

// news components
import CountryBreakdownCard from '../CountryBreakdownCard/CountryBreakdownCard';
import DailyNewsCard from '../DailyNewsCard/DailyNewsCard';
// import LanguageBreakdownCard from '../LanguageBreakdownCard/LanguageBreakdownCard';
import SentimentChartCard from '../SentimentChartCard/SentimentChartCard';
import WordCloudCard from '../WordCloudCard/WordCloudCard';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const MeltwaterNewsAnalytics = () => {
  const {category, news, sentiment} = useSelector((state) => ({
    category: state.news.category,
    news: state.news.news,
    sentiment: state.news.sentiment,
  }));

  const analytics = generateNewsAnalyticsService({
    category,
    news,
    sentiment,
  });

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <DailyNewsCard
          totalDocuments={analytics.numberOfArticles}
          documentsPerDay={analytics.documentsPerDay}
        />
      </Column>
      <Column span={24}>
        <WordCloudCard words={analytics.keyPhrases} />
      </Column>
      <Column span={24}>
        <SentimentChartCard
          negative={analytics.sentimentCount.negative}
          neutral={analytics.sentimentCount.neutral}
          positive={analytics.sentimentCount.positive}
        />
      </Column>
      <Column span={24}>
        <Row gutter={[20, 20]}>
          <Column span={24}>
            <CountryBreakdownCard countries={analytics.documentsPerCountry} />
          </Column>
          {/* <Column span={24}>
            <LanguageBreakdownCard
              languages={
                analytics.documentsPerLanguage
              }
            />
          </Column> */}
        </Row>
      </Column>
    </Row>
  );
};

export default MeltwaterNewsAnalytics;
