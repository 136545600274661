// company lib
import generateCompanyFilter from '../lib/generateFilter.lib.company';

// tag lib
import isTagAttachedToCompany from '../../tag/lib/isAttachedToCompany.lib.tag';

export default generateCompanyFilter({
  filter: 'tag',
  label: 'Tags',
  fn: ({entry, filterValue, tags: allTags}) => {
    const tags = filterValue || [];
    const companyTags = [...allTags].filter((tag) =>
      isTagAttachedToCompany({company: entry, tag})
    );
    return (
      !tags.length || tags.some((tag) => companyTags.find(({id}) => id === tag))
    );
  },
  props: {
    mode: 'multiple',
    filterOption: (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
  },
});
