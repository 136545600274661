// alert events
import generateAlertsEvent from '../../events/generateAlerts.event.alert';

// alert lib
import generateAlertId from '../../lib/generateAlertId.lib.alert';
import parseAlertMessage from '../../lib/parseAlertMessage.lib.alert';

// alert redux reducer
import {setGeneralAlertData as setGeneralAlertDataAction} from '../../redux/reducer.redux.alert';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// notification events
import generateNotificationsEvent from '../../../notification/events/generateNotifications.event.notification';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// workflow events
import workflowCreatedEvent from '../../../workflow/events/created.event.workflow';
import workflowDeletedEvent from '../../../workflow/events/deleted.event.workflow';
import workflowUpdatedEvent from '../../../workflow/events/updated.event.workflow';

// workflow services
import listWorkflowsService from '../../../workflow/services/list.service.workflow';
import getFlatTriggerOptionsService from '../../../workflow/services/getFlatTriggerOptions.service.workflow';

class AlertsGeneratorContainer extends Component {
  static propTypes = {
    alerts: PropTypes.object,
    alertsInitialized: PropTypes.bool,
    children: PropTypes.node,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
    suppliers: PropTypes.array,
  };

  componentDidMount() {
    this.generateAlerts();
    this.props.subscribe(
      generateAlertsEvent.subscribe(this.generateAlerts),
      workflowCreatedEvent.subscribe(this.generateAlerts),
      workflowDeletedEvent.subscribe(this.generateAlerts),
      workflowUpdatedEvent.subscribe(this.generateAlerts)
    );
  }

  generateAlerts = async ({
    alerts = this.props.alerts,
    suppliers = this.props.suppliers,
  } = {}) => {
    const {dispatch} = this.props;

    const supplierWithAlerts = Object.entries(alerts)
      .map(([companyId, listOfAlerts]) => {
        const supplier = [...suppliers].find(
          ({CompanyId}) => `${CompanyId}` === `${companyId}`
        );
        return !!supplier
          ? {
              supplierId: supplier.CompanyId,
              supplierName: supplier.CompanyName,
              alerts: listOfAlerts,
            }
          : null;
      })
      .filter((supplierWithAlerts) => !!supplierWithAlerts);

    const workflows = await listWorkflowsService();
    const flatTriggerOptions = getFlatTriggerOptionsService();

    const parsedAlerts = [...workflows].reduce(
      (combinedAlerts, {settings: workflow}) => {
        const includedCompanies = !!workflow.scope.length
          ? [...supplierWithAlerts].filter(({supplierId}) =>
              workflow.scope.includes(`${supplierId}`)
            )
          : [...supplierWithAlerts];
        const excludedCompanies = !!workflow.excludeCompanies.length
          ? [...includedCompanies].filter(
              ({supplierId}) =>
                !workflow.excludeCompanies.includes(`${supplierId}`)
            )
          : [...includedCompanies];
        const supplierAlerts = [...excludedCompanies]
          .map((supplier) => {
            const parsedSupplierAlerts = [...supplier.alerts]
              .filter(
                (alert) =>
                  workflow.trigger.includes(alert.Type) &&
                  (alert.Period ||
                    Number(alert.Period) === Number(workflow.period)) &&
                  (!alert.Threshold ||
                    Number(alert.Threshold) === Number(workflow.threshold))
              )
              .map((alert) => {
                const trigger = [...flatTriggerOptions].find(
                  (triggerOption) => triggerOption.value === alert.Type
                );
                return {
                  ...alert,
                  id: generateAlertId({alert, supplier}),
                  message: parseAlertMessage({alert, trigger}),
                  workflow: {
                    id: workflow.id,
                    actions: workflow.actions,
                    actionsPerCompany: workflow.actionsPerCompany,
                  },
                  supplierId: supplier.supplierId,
                  supplierName: supplier.supplierName,
                };
              });
            return parsedSupplierAlerts;
          })
          .flat();
        const uniqueSupplierAlerts = [...supplierAlerts].filter(
          (supplierAlert) =>
            ![...combinedAlerts].find(
              (existingAlert) => existingAlert.id === supplierAlert.id
            )
        );
        return [...combinedAlerts, ...uniqueSupplierAlerts];
      },
      []
    );

    console.log({parsedAlerts});

    generateNotificationsEvent.publish({alerts: parsedAlerts});

    dispatch(
      setGeneralAlertDataAction({alertsInitialized: true, alerts: parsedAlerts})
    );
  };

  render() {
    const {alertsInitialized, children} = this.props;
    return alertsInitialized ? children : <FullScreenLoader />;
  }
}

export default connect((state) => ({
  alerts: state?.dashboard?.tenantData?.Alerts || {},
  alertsInitialized: state.alert.alertsInitialized,
  suppliers: state.supplier.suppliersForSearch || [],
}))(subscriptionHOC(AlertsGeneratorContainer));
