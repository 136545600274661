// haloGpt components
import Icon from '../../../../haloGpt/components/HaloGptIcon/HaloGptIcon';

// styled components
import styled from 'styled-components';

const HaloGptIcon = styled(Icon)`
  display: block;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
`;

export default HaloGptIcon;
