// ant components
import {Col as Column, Row} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';
import Title from '../../../layout/components/Title/Title';

// local components
import Trend from './components/Trend';

// local lib
import getChartData from './lib/getChartData.lib';
import getTrendIndicator from './lib/getTrendIndicator.lib';

// news components
import DailyNewsChart from '../DailyNewsChart/DailyNewsChart';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// score components
import HighlightedScore from '../../../score/components/HighlightedScore/HighlightedScore';

const DailyNewsCard = ({totalDocuments = 0, documentsPerDay = {}}) => {
  const chartData = getChartData(documentsPerDay);
  const trend = getTrendIndicator(chartData);
  const increasingTrend = trend === 'increasing';

  return (
    <StatementBlock padding title="News Trends">
      <Row gutter={[20, 20]}>
        <Column span={5}>
          <Title heading="H4">Total Articles</Title>
          <HighlightedScore>
            {totalDocuments}
            {!!trend && (
              <Trend increasing={increasingTrend}>
                <i
                  className={`mdi mdi-triangle-small-${
                    increasingTrend ? 'up' : 'down'
                  }`}
                />
              </Trend>
            )}
          </HighlightedScore>
        </Column>
        <Column span={19}>
          <DailyNewsChart data={chartData} />
        </Column>
      </Row>
    </StatementBlock>
  );
};

DailyNewsCard.propTypes = {
  totalDocuments: PropTypes.number,
  documentsPerDay: PropTypes.object,
};

export default DailyNewsCard;
