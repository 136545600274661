// local constants
import DEFAULT_MELTWATER_REDUX_STATE from '../constants/defaultState.constant.redux.meltwater';

const resetNewsAction = (state, {payload = {}} = {}) => ({
  ...state,
  ...DEFAULT_MELTWATER_REDUX_STATE,
  ...payload,
});

export default resetNewsAction;
