import styled from 'styled-components';

// layout colors
import white from '../../../../layout/colors/white.color.layout';
import blueGrey400Color from '../../../../layout/colors/blueGrey400.color.layout';

const Title = styled.div`
  display: inline-block;
  font-size: 14px;
  padding: 3px 6px;
  font-weight: 700;
  color: ${white};
  background-color: ${blueGrey400Color};

  @media print {
    font-size: 14px;
  }
`;

export default Title;
